<template>
  <div class="container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Container"
}
</script>

<style scoped>
.container {
  background: #292E33;
  border: 1px solid #394047;
  border-radius: 12px;
}
</style>