import Vue from 'vue'
import Vuex from 'vuex'
import session from "@/session";

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        name: session.getName(),
        roles: session.getRoles(),
        token: session.getToken(),
        header: session.getHeader(),
    },
    getters: {
        name: state => state.name,
        header: state => state.header,
        anonymous: state => !state.token,
        authenticated: state => !!state.token,
    },
    mutations: {
        login: function (state, token) {
            session.create(token);
            state.name = session.getName();
            state.roles = session.getRoles();
            state.token = session.getToken();
            state.header = session.getHeader();
        },
        logout: function (state) {
            session.remove();
            state.name = null;
            state.roles = null;
            state.token = null;
            state.header = null;
        }
    },
    actions: {
        login({commit}, token) {
            commit('login', token);
        },
        logout({commit}) {
            commit('logout');
        }
    }
});

export default store
