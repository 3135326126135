<template>
  <a class="button" :href="href" target="_blank">
    <img class="icon" :src="getFlagURL(icon)" alt=""/>
    <div class="text">{{ text }}</div>
  </a>
</template>

<script>
export default {
  name: "ContactButton",
  props: ["href", "icon", "text"],
  methods: {
    getFlagURL(icon) {
      return require('../assets/icons/'+icon+'.svg')
    }
  }
}
</script>

<style scoped>
.button {
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 16px;
}
.icon {
  width: 32px;
  height: 32px;
}
.text {
  margin-left: 8px;
  font-size: 16px;
  font-weight: 700;
}
</style>