<template>
  <div class="login" @keydown.esc="$router.push('/')" @mousedown.self="$router.push('/')">
    <Container class="login__container">
      <h2>Вхід</h2>
      <form class="login__form" v-if="generated" @submit.prevent="validateToken">
        <label for="code">Код</label>
        <input id="code" autocomplete="off" type="text" v-model="code" pattern="[0-9]+" />
        <button type="submit" :disabled="!code">Увійти</button>
      </form>
      <form class="login__form" v-else @submit.prevent="generateToken">
        <label for="phone">Телефон</label>
        <input ref="phone" id="phone" autocomplete="off" type="tel" placeholder="380000000000" pattern="380[0-9]{9}" v-model="distinguisher" />
        <button type="submit" :disabled="!distinguisher">Надіслати код</button>
      </form>
    </Container>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import Container from "@/components/Container";

export default {
  name: "Login",
  components: {Container},
  data() {
    return {
      id: null,
      code: null,
      until: null,
      generated: false,
      distinguisher: null
    }
  },
  mounted() {
    this.$refs.phone.focus();
  },
  methods: {
    ...mapActions([
      'login',
      'logout'
    ]),
    generateToken: function () {
      if (!this.distinguisher) return
      this.$apollo.mutate({
        mutation: require("../graphql/GenerateToken.graphql"),
        variables: {
          input: {
            distinguisher: this.distinguisher
          }
        }
      }).then(({data}) => {
        const id = data.generateToken.id;
        if (id) this.id = id;
      }).catch((error) => {
        console.error(error);
      });
      this.generated = true;
    },
    validateToken: function () {
      if (!this.id) return
      if (!this.code) return
      this.$apollo.mutate({
        mutation: require("../graphql/ValidateToken.graphql"),
        variables: {
          input: {
            id: this.id,
            code: this.code
          }
        }
      }).then(({data}) => {
        const token = data.validateToken.token;
        if (token) {
          this.login(token);
          this.$router.push("/");
        }
      }).catch((error) => {
        console.error(error);
        this.logout();
      });
    }
  }
}
</script>

<style scoped>
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.9);
}
.login__container {
  width: 300px;
  padding: 20px;
}
.login__container h2 {
  margin: 0 0 10px 0;
}
.login__form {
  font-size: 16px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.login__form input {
  color: #fff;
  width: auto;
  font-size: 16px;
  background: none;
  border: none;
  margin: 10px 0;
  border-bottom: 2px solid #30363B;
}
.login__form input:focus{
  outline: none;
  border-bottom: 2px solid #fff;
  transition: border-bottom-color 0.6s linear;
}
.login__form button {
  color: #fff;
  padding: 10px;
  font-size: 16px;
  background: none;
  border: 2px solid #fff;
  transition: border 0.6s linear, color 0.6s linear;
}
.login__form button:disabled {
  color: #30363B;
  border: 2px solid #30363B;
}
</style>