<template>
  <div class="currency">
    <div class="currency__name">{{base.codeAlpha}}</div>
    <div class="currency__separator">/</div>
    <div class="currency__name">{{quote.codeAlpha}}</div>
  </div>
</template>

<script>
export default {
  name: "TickersCurrencyPair",
  props: [
      "base",
      "quote"
  ]
}
</script>

<style scoped>
.currency {
  display: flex;
  align-items: center;
  justify-content: center;
}
.currency__separator {
  margin: 0 5px;
  color: #56606B;
  font-size: 14px;
  font-weight: 400;
}
.currency__name {
  font-size: 16px;
  font-weight: 800;
  text-align: center;
}
</style>