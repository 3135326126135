<template>
  <Container class="policy">
    <div class="policy__content">
      <h3>Privacy Policy</h3>
      <p>
        Carte Blanche Sp. z o.o. built the Goverla.UA app and the website as
        a Free app and the website. This SERVICE is provided by
        Carte Blanche Sp. z o.o. at no cost and is intended for use as
        is.
      </p>
      <p>
        This page is used to inform visitors regarding our
        policies with the collection, use, and disclosure of Personal
        Information if anyone decided to use our Service.
      </p>
      <p>
        If you choose to use our Service, then you agree to
        the collection and use of information in relation to this
        policy. The Personal Information that we collect is
        used for providing and improving the Service. We will not use or share your information with
        anyone except as described in this Privacy Policy.
      </p>
      <p>
        The terms used in this Privacy Policy have the same meanings
        as in our Terms and Conditions, which is accessible at
        Goverla.UA unless otherwise defined in this Privacy Policy.
      </p>
      <h3>Information Collection and Use</h3>
      <p>
        For a better experience, while using our Service, we
        may require you to provide us with certain personally
        identifiable information, including but not limited to public available information. The information that
        we request will be retained by us and used as described in this privacy policy.
      </p>
      <p>
        The app and the website does use third party services that may collect
        information used to identify you.
      </p>
      <p>
        Link to privacy policy of third party service providers used
        by the app and the website
      </p>
      <ul>
        <li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</a></li>
        <li><a href="https://firebase.google.com/policies/analytics" target="_blank" rel="noopener noreferrer">Google Analytics for Firebase</a></li>
        <li><a href="https://firebase.google.com/support/privacy/" target="_blank" rel="noopener noreferrer">Firebase Crashlytics</a></li>
      </ul>
      <h3>Log Data</h3>
      <p>
        We want to inform you that whenever you
        use our Service, in a case of an error in the app or the website
        we collect data and information (through third party
        products) on your phone called Log Data. This Log Data may
        include information such as your device Internet Protocol
        (“IP”) address, device name, operating system version, the
        configuration of the app and the website when utilizing our Service,
        the time and date of your use of the Service, and other
        statistics.
      </p>
      <h3>Cookies</h3>
      <p>
        Cookies are files with a small amount of data that are
        commonly used as anonymous unique identifiers. These are sent
        to your browser from the websites that you visit and are
        stored on your device's internal memory.
      </p>
      <p>
        This Service does not use these “cookies” explicitly. However,
        the app and the website may use third party code and libraries that use
        “cookies” to collect information and improve their services.
        You have the option to either accept or refuse these cookies
        and know when a cookie is being sent to your device. If you
        choose to refuse our cookies, you may not be able to use some
        portions of this Service.
      </p>
      <h3>Service Providers</h3>
      <p>
        We may employ third-party companies and
        individuals due to the following reasons:
      </p>
      <ul>
        <li>to facilitate our Service;</li>
        <li>to provide the Service on our behalf;</li>
        <li>to perform Service-related services; or</li>
        <li>to assist us in analyzing how our Service is used.</li>
      </ul>
      <p>
        We want to inform users of this Service
        that these third parties have access to your Personal
        Information. The reason is to perform the tasks assigned to
        them on our behalf. However, they are obligated not to
        disclose or use the information for any other purpose.
      </p>
      <h3>Security</h3>
      <p>
        We value your trust in providing us your
        Personal Information, thus we are striving to use commercially
        acceptable means of protecting it. But remember that no method
        of transmission over the internet, or method of electronic
        storage is 100% secure and reliable, and we cannot
        guarantee its absolute security.
      </p>
      <h3>Links to Other Sites</h3>
      <p>
        This Service may contain links to other sites. If you click on
        a third-party link, you will be directed to that site. Note
        that these external sites are not operated by us.
        Therefore, we h3ly advise you to review the
        Privacy Policy of these websites. We have
        no control over and assume no responsibility for the content,
        privacy policies, or practices of any third-party sites or
        services.
      </p>
      <h3>Children’s Privacy</h3>
      <p>
        These Services do not address anyone under the age of 18.
        We do not knowingly collect personally
        identifiable information from children under 18. In the case
        we discover that a child under 18 has provided
        us with personal information, we immediately
        delete this from our servers. If you are a parent or guardian
        and you are aware that your child has provided us with
        personal information, please contact us so that
        we will be able to do necessary actions.
      </p>
      <h3>Changes to This Privacy Policy</h3>
      <p>
        We may update our Privacy Policy from
        time to time. Thus, you are advised to review this page
        periodically for any changes. We will
        notify you of any changes by posting the new Privacy Policy on
        this page.
      </p>
      <p>This policy is effective as of 2020-10-01</p>
      <h3>Contact Us</h3>
      <p>
        If you have any questions or suggestions about our
        Privacy Policy, do not hesitate to contact us at info[at]carte-blanche.group.
      </p>
    </div>
  </Container>
</template>

<script>
import Container from "@/components/Container";
export default {
  name: "Terms",
  components: {Container}
}
</script>

<style scoped>
.policy {
  margin: 20px;
}
.policy__content {
  margin: 20px;
}
.policy__content li {
  margin-left: 20px;
}
.policy__content h1, h2, h3, h4, h5, h6 {
  margin-top: 1em;
}
.policy__content a {
  text-decoration: underline;
}
.policy__content p {
  margin-top: 1em;
}
</style>