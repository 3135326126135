import jwt_decode from "jwt-decode";

const TOKEN_KEY = 'g-token';

export default {
    getToken() {
        const token = sessionStorage.getItem(TOKEN_KEY) || null;
        if (token) {
            const claims = jwt_decode(token)
            const expired = claims.exp < Date.now()/1000;
            if (expired) {
                sessionStorage.removeItem(TOKEN_KEY);
                return null
            }
        }
        return token
    },
    getHeader() {
        const token = this.getToken();
        return token ? {authorization: 'Bearer ' + token} : null
    },
    getClaims() {
        const token = this.getToken();
        return token ? jwt_decode(token) : null;
    },
    getName() {
        const claims = this.getClaims();
        if (claims) if (claims.name) return claims.name;
        return null;
    },
    getRoles() {
        const claims = this.getClaims();
        if (claims) if (claims.roles) return claims.roles;
        return null;
    },
    create(token) {
        sessionStorage.setItem(TOKEN_KEY, token);
    },
    remove() {
        sessionStorage.removeItem(TOKEN_KEY);
    }
}
