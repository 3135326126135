<template>
  <Container class="terms">
    <div class="terms__content">
      <h3>Terms &amp; Conditions</h3>
      <p>
        By downloading or using the app and the website, these terms will
        automatically apply to you – you should make sure therefore
        that you read them carefully before using the app and the website. You’re not
        allowed to copy, or modify the app and the website, any part of the app and the website, or
        our trademarks in any way. You’re not allowed to attempt to
        extract the source code of the app and the website, and you also shouldn’t try
        to translate the app and the website into other languages, or make derivative
        versions. The app and the website itself, and all the trade marks, copyright,
        database rights and other intellectual property rights related
        to it, still belong to Carte Blanche Sp. z o.o..
      </p>
      <p>
        Carte Blanche Sp. z o.o. is committed to ensuring that the app and the website is
        as useful and efficient as possible. For that reason, we
        reserve the right to make changes to the app and the website or to charge for
        its services, at any time and for any reason. We will never
        charge you for the app and the website or its services without making it very
        clear to you exactly what you’re paying for.
      </p>
      <p>
        The Goverla.UA app stores and processes personal data that
        you have provided to us, in order to provide our
        Service. It’s your responsibility to keep your phone and
        access to the app and the website secure. We therefore recommend that you do
        not jailbreak or root your phone, which is the process of
        removing software restrictions and limitations imposed by the
        official operating system of your device. It could make your
        phone vulnerable to malware/viruses/malicious programs,
        compromise your phone’s security features and it could mean
        that the Goverla.UA app and the website won’t work properly or at all.
      </p>
      <p>
      The app does use third party services that declare their own
      Terms and Conditions.
      </p>
      <p>
        Link to Terms and Conditions of third party service
        providers used by the app and the website:
      </p>
        <ul>
          <li><a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Google Play Services</a></li>
          <li><a href="https://firebase.google.com/terms/analytics" target="_blank" rel="noopener noreferrer">Google Analytics for Firebase</a></li>
          <li><a href="https://firebase.google.com/terms/crashlytics" target="_blank" rel="noopener noreferrer">Firebase Crashlytics</a></li>
        </ul>
      <p>
        You should be aware that there are certain things that
        Carte Blanche Sp. z o.o. will not take responsibility for. Certain
        functions of the app and the website will require the app and the website to have an active
        internet connection. The connection can be Wi-Fi, or provided
        by your mobile network provider, but Carte Blanche Sp. z o.o.
        cannot take responsibility for the app and the website not working at full
        functionality if you don’t have access to Wi-Fi, and you don’t
        have any of your data allowance left.
      </p>
      <p>
        If you’re using the app and the website outside of an area with Wi-Fi, you
        should remember that your terms of the agreement with your
        mobile network provider will still apply. As a result, you may
        be charged by your mobile provider for the cost of data for
        the duration of the connection while accessing the app and the website, or
        other third party charges. In using the app and the website, you’re accepting
        responsibility for any such charges, including roaming data
        charges if you use the app and the website outside of your home territory
        (i.e. region or country) without turning off data roaming. If
        you are not the bill payer for the device on which you’re
        using the app and the website, please be aware that we assume that you have
        received permission from the bill payer for using the app and the website.
      </p>
      <p>
        Along the same lines, Carte Blanche Sp. z o.o. cannot always take
        responsibility for the way you use the app and the website i.e. You need to
        make sure that your device stays charged – if it runs out of
        battery and you can’t turn it on to avail the Service,
        Carte Blanche Sp. z o.o. cannot accept responsibility.
      </p>
      <p>
        With respect to Carte Blanche Sp. z o.o.’s responsibility for your
        use of the app and the website, when you’re using the app and the website, it’s important to
        bear in mind that although we endeavour to ensure that it is
        updated and correct at all times, we do rely on third parties
        to provide information to us so that we can make it available
        to you. Carte Blanche Sp. z o.o. accepts no liability for any
        loss, direct or indirect, you experience as a result of
        relying wholly on this functionality of the app and the website.
      </p>
      <p>
        At some point, we may wish to update the app and the website. The app and the website are
        currently available on Android &amp; iOS &amp; and as a website – the requirements for
        both systems(and for any additional systems we
        decide to extend the availability of the app and the website to) may change,
        and you’ll need to download the updates if you want to keep
        using the app and the website. Carte Blanche Sp. z o.o. does not promise that it
        will always update the app and the website so that it is relevant to you
        and/or works with the Android &amp; iOS version that you have
        installed on your device. However, you promise to always
        accept updates to the application and the website when offered to you, We may
        also wish to stop providing the app and the website, and may terminate use of
        it at any time without giving notice of termination to you.
        Unless we tell you otherwise, upon any termination, (a) the
        rights and licenses granted to you in these terms will end;
        (b) you must stop using the app and the website, and (if needed) delete it
        from your device.
      </p>
      <h3>Changes to This Terms and Conditions</h3>
      <p>
        We may update our Terms and Conditions
        from time to time. Thus, you are advised to review this page
        periodically for any changes. We will
        notify you of any changes by posting the new Terms and
        Conditions on this page.
      </p>
      <p>
        These terms and conditions are effective as of 2020-10-01
      </p>
      <h3>Contact Us</h3>
      <p>
        If you have any questions or suggestions about our
        Terms and Conditions, do not hesitate to contact us
        at info[at]carte-blanche.group.
      </p>
    </div>
  </Container>
</template>

<script>
import Container from "@/components/Container";

export default {
  name: "Terms",
  components: {
    Container
  }
}
</script>

<style scoped>
.terms {
  margin: 20px;
}
.terms__content {
  margin: 20px;
}
.terms__content li {
  margin-left: 20px;
}
.terms__content h1, h2, h3, h4, h5, h6 {
  margin-top: 1em;
}
.terms__content a {
  text-decoration: underline;
}
.terms__content p {
  margin-top: 1em;
}
</style>