<template>
  <div>
    <Header/>
    <router-view/>
    <Footer/>
    <div style="height: 1px;"></div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "App",
  components: {
    Footer,
    Header
  }
}
</script>

<style>
@import "~lato-font/css/lato-font.min.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", Helvetica, sans-serif;
}
html, body {
  width: 100%;
  height: 100%;
}
body, a {
  color: #fff;
  text-decoration: none;
}
body {
  cursor: default;
  background: #1A1C1E;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background: transparent;
  margin: 10px 0;
}
::-webkit-scrollbar-thumb {
  background-color: #394047;
  border-radius: 3px;
}
</style>
