<template>
  <Container class="tickers">
    <div class="tickers__caption">
      <TickersKind :kinds="kinds" :selected.sync="kind" />
      <span>Avg</span>
    </div>
    <TickersChart :ticker="selected" v-if="selected"/>
    <div class="tickers__header">
      <div class="tickers__header__currency">Валютна пара</div>
      <div class="tickers__header__value">Bid</div>
      <div class="tickers__header__value">Ask</div>
    </div>
    <div class="tickers__rows">
      <TickersRow
          v-for="ticker in tickers"
          :key="ticker.id"
          :ticker="ticker"
          :selected="selected"
          @click.native="selected=ticker"
      />
    </div>
  </Container>
</template>

<script>
import Container from "@/components/Container";
import TickersRow from "@/components/TickersRow";
import TickersChart from "@/components/TickersChart";
import TickersKind from "@/components/TickersKind";

export default {
  name: 'TickersTable',
  components: {
    TickersKind,
    TickersChart,
    TickersRow,
    Container
  },
  data() {
    return {
      tickers: [],
      selectedRow: null,
      selectedKind: null,
      kinds: [
        {id: 'FOREX', name: 'Форекс'},
        {id: 'CRYPTO', name: 'Крипта'},
      ]
    }
  },
  computed: {
    selected: {
      get: function () {
        return this.selectedRow || this.tickers[0] || null;
      },
      set: function (ticker) {
        this.selectedRow = ticker
      }
    },
    kind: {
      get: function () {
        return this.selectedKind || this.kinds[0] || null;
      },
      set: function (kind) {
        this.selectedRow = null;
        this.selectedKind = kind
      }
    }
  },
  apollo: {
    tickers: {
      query: require('../graphql/Tickers.graphql'),
      fetchPolicy: "network-only",
      update: data => data.tickers,
      variables() {
        return {kind: this.kind.id}
      },
      error: function(err) {
        console.error(err);
      },
      subscribeToMore: {
        document: require('../graphql/TickersSubscription.graphql'),
        variables() {
          return {kind: this.kind.id}
        },
        updateQuery: ((previousQueryResult, {subscriptionData}) => {
          if (!subscriptionData.data) return previousQueryResult;
          const updates = subscriptionData.data.subscribeTickers;
          const updatesById = Object.assign({}, ...updates.map(s => ({[s.id]: s})));
          if (previousQueryResult) {
            previousQueryResult.tickers.map(prev => {
              const updateById = updatesById[prev.id]
              if (!updateById) return prev;
              if (updateById.bid) prev.bid.close = updateById.bid.close;
              if (updateById.ask) prev.ask.close = updateById.ask.close;
              return prev
            });
          }
          return previousQueryResult
        })
      }
    }
  }
}
</script>

<style scoped>
.tickers {
  min-width: 480px;
  min-height: 628px;
  display: flex;
  flex-direction: column;
}
.tickers__caption {
  display: flex;
  height: 64px;
  padding: 0 24px;
  align-items: center;
  justify-content: space-between;
}
.tickers__caption header {
  font-size: 18px;
  font-weight: 900;
}
.tickers__caption span {
  font-size: 16px;
  font-weight: 400;
}
.tickers__header {
  display: flex;
  align-items: center;
  height: 40px;
  color: #6D7A88;
  padding: 0 6px 0 16px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  box-shadow: 0 5px 5px rgba(26, 28, 30, 0.25);
}
.tickers__header__currency {
  width: 40%;
}
.tickers__header__value {
  width: 30%;
}
.tickers__rows {
  height: 551px;
  cursor: pointer;
  overflow-y: scroll;
}
@media only screen and (max-width: 1023px) {
  .tickers {
    width: 100%;
    min-width: auto;
    min-height: 480px;
    flex-direction: column;
  }
}
@media only screen and (max-width: 767px) {
  .tickers__rows {
    height: 233px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1673px) {
  .tickers__rows {
    height: 318px;
  }
}
</style>
