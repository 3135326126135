import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "@/components/Home";
import Terms from "@/components/Terms";
import Policy from "@/components/Policy";
import Login from "@/components/Login";
import store from "@/store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [{
      path: 'login',
      component: Login,
      meta: { requiresAnonymous: true }
    }],
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/policy',
    name: 'Policy',
    component: Policy
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAnonymous)) {
    if (!store.getters.anonymous) {
      next({path: '/'});
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresAuthenticated)) {
    if (!store.getters.authenticated) {
      next({path: '/login'});
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
