<template>
  <div class="chart">
    <div class="chart__header">
      <div class="chart__name">{{ticker.base.codeAlpha}}</div>
      <div class="chart__separator">/</div>
      <div class="chart__name">{{ticker.quote.codeAlpha}}</div>
    </div>
    <Linechart class="chart__image" v-if="dataset" :chart-data="dataset" :options="options"/>
  </div>
</template>

<script>
import Linechart from "@/linechart";
export default {
  name: "TickersChart",
  components: {
    Linechart
  },
  props: ["ticker"],
  data() {
    return {
      history: {
        bid: [],
        ask: []
      },
      options: {
        defaultFontFamily: '"Lato"',
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            offset: false,
            type: 'time',
            time: {
              unit: 'minute',
              stepSize: 30,
              displayFormats: {
                minute: 'H:mm'
              }
            },
            ticks: {
              maxTicksLimit: 9,
              maxRotation: 0,
              padding: 10,
              fontColor: '#56606B',
              fontFamily: 'Lato'
            },
            gridLines: {
              color: '#363D42',
              drawTicks: false
            }
          }],
          yAxes: [{
            ticks: {
              precision: 10,
              maxTicksLimit: 8,
              maxRotation: 0,
              minRotation: 0,
              padding: 10,
              fontColor: '#56606B',
              fontFamily: 'Lato'
            },
            gridLines: {
              color: '#363D42',
              drawTicks: false
            }
          }]
        },
        legend: {
          display: false
        },
        tooltips: {
          enabled: false,
        },
        elements: {
          point: {
            radius: 0
          },
          line: {
            tension: 0.1,
            borderWidth: 1,
            borderColor: '#FF5A4C',
            backgroundColor: 'rgba(253, 65, 60, 0.15)'
          }
        },
        layout: {
          padding: {
            left: 5,
            right: 5,
          }
        }
      }
    }
  },
  computed: {
    dataset: function () {
      return {
        datasets: [
          {
            data: this.history.bid.map(value => {
              return {
                y: value.close.toFixed(this.ticker.precision),
                t: value.updatedAt
              }
            })
          },
          {
            data: this.history.ask.map(value => {
              return {
                y: value.close.toFixed(this.ticker.precision),
                t: value.updatedAt
              }
            })
          }
        ]
      }
    }
  },
  apollo: {
    history: {
      query: require('../graphql/TickersHistory.graphql'),
      fetchPolicy: "network-only",
      pollInterval: 300000,
      update: data => data.tickersHistory,
      variables () {
        return {
          id: this.ticker.id,
          resolution: 'RESOLUTION_30_MINUTES',
        }
      },
    }
  }
}
</script>

<style scoped>
.chart {
  overflow: hidden;
}
.chart__header {
  display: flex;
  justify-content: center;
}
.chart__separator {
  margin: 0 5px;
  color: #56606B;
  font-size: 14px;
  font-weight: 400;
}
.chart__name {
  font-size: 16px;
  font-weight: 800;
  text-align: center;
}
.chart__image {
  height: 300px;
}
@media only screen and (max-width: 767px) {
  .chart__image {
    height: 200px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1673px) {
  .chart__image {
    height: 200px;
  }
}
</style>