<template>
  <div class="row" :class="{selected: selected === ticker}">
    <div v-if="selected === ticker" class="row__dot"><span></span></div>
    <TickersCurrencyPair class="row__currency" :base="ticker.base" :quote="ticker.quote" />
    <TickersValue class="row__value" :value="ticker.bid" :precision="ticker.precision" />
    <TickersValue class="row__value" :value="ticker.ask" :precision="ticker.precision" />
  </div>
</template>

<script>
import TickersValue from "@/components/TickersValue";
import TickersCurrencyPair from "@/components/TickersCurrencyPair";

export default {
  name: 'TickersRow',
  props: [
      "ticker",
      "selected",
  ],
  components: {
    TickersValue,
    TickersCurrencyPair
  }
}
</script>

<style scoped>
.row {
  z-index: 0;
  height: 48px;
  display: flex;
  padding: 0 0 0 16px;
  position: relative;
  border-top: 1px solid #212529;
}
.row:hover {
  background-color: #30363B;
}
.row.selected {
  background-color: #30363B;
}
.row__dot {
  width: 6px;
  height: 48px;
  display: flex;
  position: absolute;
  align-items: center;
}
.row__dot span {
  width: 6px;
  height: 6px;
  display: block;
  background-color: #01B075;
  box-shadow: 0 0 8px rgba(3, 228, 152, 0.66);
  border-radius: 100%;
}
.row__currency {
  width: 40%;
}
.row__value {
  width: 30%;
}
</style>