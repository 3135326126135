<template>
  <div class="adrow">
    <ins class="adsbygoogle adinfeed"
       style="display:block"
       data-ad-format="fluid"
       data-ad-layout-key="-hc-2+l-8f+hk"
       data-ad-client="ca-pub-1597812066507411"
       data-ad-slot="1149250582"></ins>
  </div>
</template>

<script lang="ts">

export default {
  name: "RatesAd",
  mounted() {
    if (typeof window.adsbygoogle === "undefined") {
      const script = document.createElement("script");
      script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1597812066507411";
      script.async = true;
      script.crossOrigin = "anonymous"
      document.head.appendChild(script);
    }
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }
}
</script>

<style scoped>
.adrow {
  display: flex;
  height: 85px;
  border-top: 1px solid #212529;
}
.adinfeed {
  width: 100%;
  height: 85px;
}
</style>
