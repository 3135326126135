<template>
  <router-link class="logo" to="/">
    <img class="logo__image" src="@/assets/logo.png"/>
    <div class="logo__slogan">Будь в курсі!</div>
  </router-link>
</template>

<script>
export default {
  name: "Logo",
}
</script>

<style scoped>
.logo {
  display: block;
  height: 50px;
}
.logo__image {
  height: 50px;
  display: block;
}
.logo__slogan {
  left: 35px;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
}
@media (max-width: 1023px) {
  .logo__slogan {
    display: none;
  }
}
</style>