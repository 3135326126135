<template>
  <header class="header">
    <Logo class="logo"/>
    <div class="buttons">
      <ContactButton style="color: #56606B" href="https://twitter.com/goverlaua" icon="ic-twitter" text="@goverlaua"/>
      <ContactButton style="color: #56606B" href="https://t.me/goverla_ua" icon="ic-telegram" text="@goverla_ua"/>
      <ContactButton style="color: #ffffff" href="tel:+380503397777" icon="ic-phone" text="(050) 339-77-77"/>
      <User/>
    </div>
  </header>
</template>

<script>
import ContactButton from "@/components/ContactButton";
import Logo from "@/components/Logo";
import User from "@/components/User";

export default {
  name: "Header",
  components: {
    Logo,
    User,
    ContactButton
  },
}
</script>

<style scoped>
.header {
  height: 96px;
  display: flex;
  padding: 0 80px;
  margin-bottom: 24px;
  justify-content: space-between;
}
.logo {
  margin: 16px 0 16px 0;
}
.buttons {
  margin: 16px 0 16px 0;
  display: flex;
}
@media (max-width: 1023px) {
  .buttons {
    display: none;
  }
  .header {
    height: 64px;
    margin-bottom: 0;
    justify-content: center;
  }
}
</style>