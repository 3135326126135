<template>
  <div class="kind">
    <template v-for="kind in kinds">
      <input
          class="kind__input"
          type="radio"
          name="kind"
          :id="kind.id"
          :key="'input-'+kind.id"
          :checked="selected===kind"
          @change="change(kind)"
      />
      <label class="kind__label" :for="kind.id" :key="'label-'+kind.id">{{kind.name}}</label>
    </template>
  </div>
</template>

<script>
export default {
  name: "TickersKind",
  props: [
    "kinds",
    "selected",
  ],
  methods: {
    change: function (kind) {
      this.$emit('update:selected', kind);
    }
  }
}
</script>

<style scoped>
.kind {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  border-radius: 40px;
  background-color: #212529;
}
.kind__input {
  display: none;
}
.kind__label {
  cursor: pointer;
  padding: 8px 14px;
  height: 40px;
  color: #6D7A88;
  font-size: 18px;
  font-weight: 900;
  border-radius: 40px;
  background-color: #212529;
}
.kind__input:checked + .kind__label {
  transition: 0.3s ease-out ;
  color: #FD413C;
  background-color: #1A1C1E;
}

</style>