<template>
  <div class="value" :class="itemClass">
    {{close}}<span class="value__icon" :class="itemClass"></span>
  </div>
</template>

<script>
export default {
  name: "TickersValue",
  props: [
    "value",
    "precision",
  ],
  data: function() {
    return  {
      itemClass: ''
    }
  },
  computed: {
    close: function () {
      return this.value.close.toFixed(this.precision);
    }
  },
  methods: {
    up: function () {
      this.itemClass = this.itemClass === 'up-1' ? 'up-2' : 'up-1';
    },
    down: function () {
      this.itemClass = this.itemClass === 'down-1' ? 'down-2' : 'down-1';
    }
  },
  watch: {
    close: {
      handler: function (val, oldVal) {
        val = parseFloat(val);
        oldVal = parseFloat(oldVal);
        if (val && oldVal) {
          const delta = parseFloat((val - oldVal).toFixed(this.precision));
          if (delta > 0) {
            this.up()
          } else if (delta < 0) {
            this.down()
          }
        }
        return val
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.value {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  padding-left: 16px;
}
.value__icon {
  display: block;
  width: 16px;
  height: 16px;
  margin-left: 4px;
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
}
span.up-1 {
  opacity: 0;
  background-image: url("../assets/icons/ic-up.svg");
  animation: fadeOut-1 3s;
}

span.up-2 {
  opacity: 0;
  background-image: url("../assets/icons/ic-up.svg");
  animation: fadeOut-2 3s;
}

span.down-1 {
  opacity: 0;
  background-image: url("../assets/icons/ic-down.svg");
  animation: fadeOut-3 3s;
}

span.down-2 {
  opacity: 0;
  background-image: url("../assets/icons/ic-down.svg");
  animation: fadeOut-4 3s;
}

@keyframes fadeOut-1 {
  from {opacity: 1;}
  to {opacity: 0;}
}

@keyframes fadeOut-2 {
  from {opacity: 1;}
  to {opacity: 0;}
}

@keyframes fadeOut-3 {
  from {opacity: 1;}
  to {opacity: 0;}
}

@keyframes fadeOut-4 {
  from {opacity: 1;}
  to {opacity: 0;}
}

@keyframes upAnimation-1 {
  from {
    color: #01B075;
    background-color: #264E46;
  }
  to {
    color: #fff;
    background-color: inherit;
  }
}

@keyframes upAnimation-2 {
  from {
    color: #01B075;
    background-color: #264E46;
  }
  to {
    color: #fff;
    background-color: inherit;
  }
}

@keyframes downAnimation-1 {
  from {
    color: #fd413c;
    background-color: #543235;
  }
  to {
    color: #fff;
    background-color: inherit;
  }
}

@keyframes downAnimation-2 {
  from {
    color: #fd413c;
    background-color: #543235;
  }
  to {
    color: #fff;
    background-color: inherit;
  }
}

.up-1 {
  animation-name: upAnimation-1;
  animation-duration: 3s;
}
.up-2 {
  animation-name: upAnimation-2;
  animation-duration: 3s;
}

.down-1 {
  animation-name: downAnimation-1;
  animation-duration: 3s;
}

.down-2 {
  animation-name: downAnimation-2;
  animation-duration: 3s;
}
</style>