import Vue from 'vue'
import VueGtag from "vue-gtag";
import VueCookies from 'vue-cookies'
import App from './App.vue'
import apolloProvider from './vue-apollo'
import router from './router'
import store from './store'

Vue.config.productionTip = false
Vue.use(VueCookies);
Vue.use(VueGtag, {config: { id: "G-SR5PCZJTG9" }}, router);

new Vue({
  apolloProvider,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
