<template>
  <Container class="telegram">
    <iframe src="https://goverla.ua/telegram" class="telegram-widget" id="tg-if" v-on:load="stylize"></iframe>
  </Container>
</template>

<script>
import Container from "@/components/Container";
export default {
  name: "Telegram",
  components: {Container},
  methods: {
    stylize() {
      try {
        const widget = document.getElementById("tg-if");
        const innerDoc = widget.contentDocument || widget.contentWindow.document;
        innerDoc.getElementsByTagName("html")[0].classList.add("theme_dark");
        widget.contentWindow.TWeb.toggleTheme = function(dark) {
          console.log("Theme switched to ", dark)
          innerDoc.getElementsByTagName("html")[0].classList.add("theme_dark");
        }
        innerDoc.body.addEventListener("DOMSubtreeModified", this.fixTarget);
        innerDoc.getElementById("tgme_background").remove()
        innerDoc.getElementsByClassName("tgme_header_search")[0].remove();
        innerDoc.getElementsByClassName("tgme_background_wrap")[0].style.backgroundColor = "#292E33";
        innerDoc.getElementsByClassName("tgme_channel_join_telegram")[0].remove();
        const anchors = innerDoc.getElementsByTagName("a");
        for (let i=0; i<anchors.length; i++){
          anchors[i].setAttribute("target", "_blank");
        }
      } catch (error) {
        console.log("Skipping styling widget")
        console.log(error)
      }
    },
    fixTarget(event) {
      const anchors = event.target.getElementsByTagName("a");
      for (let i=0; i<anchors.length; i++){
        anchors[i].setAttribute("target", "_blank");
      }
    }
  }
}
</script>

<style>
.telegram {
  min-width: 480px;
  height: 892px;
}
.telegram::-webkit-scrollbar {
  display: none !important;
}
.telegram-widget {
  width: 100%;
  height: 100%;
  border: none;
  box-sizing: content-box;
  border-radius: 12px;
}
@media only screen and (max-width: 1023px) {
  .telegram {
    width: 100%;
    min-width: auto;
    height: 542px !important;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1673px) {
  .telegram {
    height: 312px !important;
  }
}
</style>