<template>
  <div class="row">
    <RatesCurrency class="row__currency" :currency="rate.currency" />
    <RatesValue class="row__value" :value="rate.bid" @update:value="update({bid: $event})" :exponent="rate.currency.exponent" />
    <RatesValue class="row__value" :value="rate.ask" @update:value="update({ask: $event})" :exponent="rate.currency.exponent" />
  </div>
</template>

<script>
import RatesValue from "@/components/RatesValue";
import RatesCurrency from "@/components/RatesCurrency";

export default {
  name: "RatesRow",
  props: [
    "rate",
  ],
  components: {
    RatesCurrency,
    RatesValue
  },
  methods: {
    update: function(rate) {
      rate.id = this.rate.id;
      this.$emit('update:rate', rate);
    },
  }
}
</script>

<style scoped>
.row {
  display: flex;
  padding: 16px 0 16px 16px;
  height: 72px;
  border-top: 1px solid #212529;
}
.row__currency {
  width: 44%;
}
.row__value {
  width: 28%;
}
@media only screen and (max-width: 767px) {
  .row__currency {
    width: 40%;
  }

  .row__value {
    width: 30%;
  }
}
</style>