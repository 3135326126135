import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { split } from 'apollo-link'
import {setContext} from "apollo-link-context";
import { HttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { getMainDefinition } from "apollo-utilities";
import store from "@/store";

const HTTP_URI = 'https://api.goverla.ua/graphql'
const WEBSOCKET_URI = 'wss://api.goverla.ua/graphql'

// Install the vue plugin
Vue.use(VueApollo)

const cache = new InMemoryCache()
const authLink = setContext((_, { headers }) => {
    if (!store.getters.authenticated) return headers;
    return {
        headers: {
            ...headers,
            ...store.getters.header,
        }
    }
});
const httpLink = new HttpLink({uri: HTTP_URI})
const wsLink = new WebSocketLink({uri: WEBSOCKET_URI, options: {reconnect: true}})

const link = split(
    ({ query }) => {
        const definition = getMainDefinition(query)
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        )
    },
    wsLink,
    authLink.concat(httpLink)
)

const apolloClient = new ApolloClient({
    connectToDevTools: true,
    link: link,
    cache
})

const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
});

export default apolloProvider