<template>
  <div class="value">
    <input ref="value" @change="end" @focusout="end" v-if="editable" class="value__absolute_editable" type="text" v-model.lazy="absolute"/>
    <h3 @dblclick="begin" v-else class="value__absolute">{{ absolute }}</h3>
    <div class="value__meta">
      <div v-if="relative > 0" class="value__meta__icon relative up">{{ Math.abs(relative).toFixed(exponent) }}</div>
      <div v-else-if="relative < 0" class="value__meta__icon relative down">{{ Math.abs(relative).toFixed(exponent) }}</div>
      <div v-else class="value__meta__icon relative">{{ Math.abs(relative).toFixed(exponent) }}</div>
      <div>|</div>
      <div class="value__meta__icon updated">{{ updated_at }}</div>
    </div>
  </div>
</template>

<script>
import { parseISO, format, isToday, isYesterday } from 'date-fns';

export default {
  name: "RatesValue",
  props: [
      "value",
      "exponent",
  ],
  data() {
    return {
      editable: false
    }
  },
  methods: {
    begin: function () {
      if (!sessionStorage.getItem("g-token")) return;
      this.editable = true
      this.$nextTick(() => {
        this.$refs.value.select();
        this.$refs.value.focus();
      });
    },
    end: function () {
      this.editable = false;
    }
  },
  computed: {
    absolute: {
      get: function () {
        return (this.value.absolute / (10 ** this.exponent)).toFixed(this.exponent);
      },
      set: function (value) {
        if (!isNaN(Number(value))) {
          let abs = Math.round(value * (10 ** this.exponent));
          if (abs <= 0) return;
          this.$emit('update:value', {absolute: abs});
        }
      }
    },
    relative: function () {
      return this.value.relative / (10 ** this.exponent);
    },
    updated_at: function () {
      const updatedAt = parseISO(this.value.updatedAt);
      if (isToday(updatedAt)) return format(updatedAt, "HH:mm");
      if (isYesterday(updatedAt)) return "Вчора";
      return format(updatedAt, "dd/MM");
    }
  }
}
</script>

<style scoped>
.value {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.value__absolute {
  line-height: 24px;
  font-size: 22px;
  font-weight: 900;
}
.value__absolute_editable {
  width: 100%;
  color: #fff;
  border: none;
  text-align: center;
  font-size: 22px;
  font-weight: 900;
  outline: none;
  background-color: transparent;
  border-bottom: 2px solid #fff;
}
.value__meta {
  margin-top: 5px;
  display: flex;
}
.value__meta > div {
  display: flex;
  color: #66737F;
  font-size: 12px;
  font-weight: 400;
}
.value__meta__icon {
  margin: 0 5px;
  height: 12px;
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
}
.value__meta__icon.up {
  color: #01B075;
  padding-left: 16px;
  background-image: url("../assets/icons/ic-up.svg");
}
.value__meta__icon.down {
  color: #FD413C;
  padding-left: 16px;
  background-image: url("../assets/icons/ic-down.svg");
}
@media only screen and (max-width: 767px) {
  .value__absolute {
    font-size: 20px;
    font-weight: 900;
  }
  .value__meta__icon.relative {
    margin-right: 5px;
    font-size: 10px;
  }
  .value__meta__icon.updated {
    margin-left: 5px;
    font-size: 10px;
  }
}
</style>