<template>
  <div v-if="authenticated" class="user">
    <h3 class="user__name">{{name}}</h3>
    <a @click="logout">Вийти</a>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "User",
  components: {},
  computed: {
    ...mapGetters([
      'name',
      'authenticated',
    ])
  },
  methods: {
    ...mapActions([
      'logout'
    ])
  }
}
</script>

<style scoped>
.user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 16px;
}
.user__name {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}
.user a {
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  text-decoration: underline;
}
</style>