<template>
  <div class="currency">
    <div class="currency__flag" :style="style"/>
    <div class="currency__name">
      <h3 class="currency__code">{{currency.codeAlpha}}</h3>
      <h5 class="currency__description large">{{currency.name}}</h5>
    </div>
    <h5 class="currency__description small">{{currency.name}}</h5>
  </div>
</template>

<script>
export default {
  name: "RatesCurrency",
  props: ["currency"],
  computed: {
    flagURL() {
      return require('../assets/flags/'+this.currency.codeNumeric+'.svg')
    },
    style() {
      return {
        "background-image": "url(" + this.flagURL + ")"
      }
    }
  }
}
</script>

<style scoped>
.currency {
  display: flex;
}
.currency__flag {
  display: block;
  width: 58px;
  height: 40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.currency__name {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}
.currency__code {
  font-size: 22px;
  font-weight: 900;
}
.currency__description {
  color: #6D7A88;
  font-weight: 400;
}
.currency__description.large {
  font-size: 12px;
}
.currency__description.small {
  display: none;
  font-size: 10px;
}
@media only screen and (max-width: 767px) {
  .currency {
    flex-wrap: wrap;
  }
  .currency__code {
    font-size: 20px;
    font-weight: 900;
  }
  .currency__name {
    margin-left: 10px;
  }
  .currency__description.large {
    display: none;
  }
  .currency__description.small {
    display: block;
    width: 100%;
    margin-top: 5px;
  }
  .currency__flag {
    width: 35px;
    height: 24px;
  }
}
</style>