<template>
  <div class="popup mobile" v-if="!seenMobile && (ios || android)">
    <div v-if="android" class="popup__block">
      <h2 class="popup__prompt">В додатку зручніше!</h2>
      <p class="popup__description">Оновлення в реальному часі</p>
      <div class="popup__links">
        <a class="popup__link notnow" @click="notnow">Не зараз</a>
        <a class="popup__link store" target="_blank" href="https://play.google.com/store/apps/details?id=com.maturedevs.goverla">Відкрити додаток</a>
      </div>
    </div>
    <div v-else-if="ios" class="popup__block">
      <h2 class="popup__prompt">В додатку зручніше!</h2>
      <p class="popup__description">Тепер не потрібен веб-браузер, щоб бути в курсі. Як веб-сайт тільки крутіше.</p>
      <div class="popup__links">
        <a class="popup__link notnow" @click="notnow">Не зараз</a>
        <a class="popup__link store" target="_blank" href="https://apps.apple.com/us/app/goverla-ua/id1528716737">Відкрити додаток</a>
      </div>
    </div>
  </div>
</template>

<script>
const SEEN_MOBILE_EXP = 24;
const SEEN_MOBILE_KEY = "g-mobile-popup-seen";
import { isAndroid, isIOS } from 'mobile-device-detect';
export default {
  name: "MobilePopup",
  data() {
    return {
      ios: isIOS,
      android: isAndroid,
      seenMobile: this.$cookies.isKey(SEEN_MOBILE_KEY),
    }
  },
  methods: {
    notnow: function () {
      let expiresAt = new Date();
      expiresAt.setHours(expiresAt.getHours() + SEEN_MOBILE_EXP);
      this.$cookies.set(SEEN_MOBILE_KEY, true, expiresAt);
      this.seenMobile = true;
    },
  }
}
</script>

<style scoped>
.popup {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: rgba(26, 28, 30, 0.9);
  padding: 20px;
  box-shadow: 0 -10px 10px 0 rgba(26, 28, 30, 0.25);
}
.popup.web {
  display: flex;
  justify-content: center;
}
.popup__prompt {
  font-size: 18px;
  font-weight: 900;
  margin: 5px 0;
}
.popup__description {
  font-size: 14px;
  line-height: 16px;
  margin: 5px 0;
}
.popup__links {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.popup__link {
  font-size: 18px;
  font-weight: 900;
  padding: 10px;
  border-radius: 24px;
  border: 2px solid #fff;
}
.popup__link.notnow {
  border: 2px solid #fff;
  cursor: pointer;
}
.popup__link.modern {
  cursor: pointer;
}
.popup__link.store, .popup__link.modern {
  color: #fff;
  border: 2px solid #fd413c;
  background-color: #fd413c;
}
</style>